<template>
  <div class="image-uploader">
    <el-upload
      class="image-uploader"
      action="https://manage-mis.threeapple.cn/api/oss/upload"
      :headers="headers"
      :show-file-list="false"
      :on-success="handleSuccess"
      :on-error="handleError"
      :before-upload="beforeUpload"
      :http-request="customUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="image" />
      <i v-else class="el-icon-plus image-uploader-icon"></i>
    </el-upload>
    <div v-if="imageUrl" class="image-actions">
      <el-button type="text" size="small" @click.stop="handlePreview"
        >预览</el-button
      >
      <el-button type="text" size="small" @click.stop="handleRemove"
        >删除</el-button
      >
    </div>

    <!-- 图片预览对话框 -->
    <el-dialog :visible.sync="previewVisible" append-to-body>
      <img width="100%" :src="imageUrl" alt="Preview" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ImageUploader",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imageUrl: "",
      previewVisible: false,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.imageUrl = val;
      },
    },
  },
  methods: {
    // 自定义上传
    async customUpload(options) {
      try {
        const formData = new FormData();
        formData.append("file", options.file);

        const response = await fetch(
          "https://manage-mis.threeapple.cn/api/oss/upload",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: formData,
          }
        );

        const result = await response.text();

        if (result) {
          options.onSuccess({ code: 200, data: result });
        } else {
          options.onError(new Error("上传失败"));
        }
      } catch (error) {
        console.error("上传失败:", error);
        options.onError(error);
      }
    },

    // 上传成功
    handleSuccess(res) {
      if (res.code === 200) {
        this.imageUrl = res.data;
        this.$emit("input", res.data);
        this.$emit("success", res.data);
        this.$message.success("上传成功");
      } else {
        this.$message.error("上传失败");
      }
    },

    // 上传失败
    handleError(err) {
      console.error("上传失败:", err);
      this.$message.error(err.message || "上传失败");
    },

    // 上传前检查
    beforeUpload(file) {
      const isImage = file.type.startsWith("image/");
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage) {
        this.$message.error("只能上传图片文件!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 2MB!");
        return false;
      }
      return true;
    },

    // 预览图片
    handlePreview() {
      this.previewVisible = true;
    },

    // 删除图片
    handleRemove() {
      this.$confirm("确定删除该图片吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          this.imageUrl = "";
          this.$emit("input", "");
          this.$emit("remove");
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.image-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;

    &:hover {
      border-color: #409eff;
    }
  }

  .image-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .image {
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
  }
}

.image-actions {
  margin-top: 8px;
  text-align: center;
}
</style>
