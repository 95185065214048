<template>
  <div class="manage">
    <div class="search-area">
      <div class="search-form">
        <div class="searchParam">
          <span class="userList_titiles" v-if="userInfo.role == 1"
            >商家名称</span
          >
          <template v-if="userInfo.role == 1">
            <el-input
              v-model="choseUserName"
              placeholder="请选择商家"
              :readonly="true"
              clearable
              @click.native="openDialog"
              class="choseUserInput search-input"
            >
              <template slot="suffix">
                <i
                  v-if="choseUserName"
                  class="el-icon-circle-close el-input__icon"
                  @click.stop="removeChoseUser"
                  style="cursor: pointer"
                ></i>
              </template>
              <template slot="append">
                <el-button
                  icon="el-icon-user"
                  @click.stop="openDialog"
                ></el-button>
              </template>
            </el-input>
          </template>

          <!-- 选择了商家后显示设备选择 -->
          <template v-if="userInfo.role == 1 && queryParam.searchUserId">
            <span class="equipment_titiles">设备名称</span>
            <el-input
              v-model="equipmentName"
              placeholder="请选择设备"
              :readonly="true"
              clearable
              @click.native="openEquipmentDialog"
              class="equipment_select"
            >
              <template slot="suffix">
                <i
                  v-if="equipmentName"
                  class="el-icon-circle-close el-input__icon"
                  @click.stop="removeEquipment"
                  style="cursor: pointer"
                ></i>
              </template>
              <template slot="append">
                <el-button
                  icon="el-icon-office-building"
                  @click.stop="openEquipmentDialog"
                ></el-button>
              </template>
            </el-input>
          </template>

          <span class="showSelf_titiles" v-if="userInfo.role != 1"
            >只看自己</span
          >
          <template>
            <el-select
              v-model="queryParam.isShowSelf"
              placeholder="是否只查看自己"
              size="medium"
              :clearable="true"
              class="showSelf_select"
              v-if="userInfo.role != 1"
            >
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </template>
        </div>
        <div class="searchParam2">
          <span class="timeRange_titiles">时间周期</span>
          <template>
            <el-select
              v-model="queryParam.timeType"
              placeholder="选择搜索时间范围"
              class="timeRange_select type-select"
              size="small"
              :clearable="true"
            >
              <el-option
                v-for="item in timeRangeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <span class="time_titiles">时间范围</span>
          <template>
            <div class="block">
              <el-date-picker
                v-model="time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                class="time_select"
                size="small"
                clearable
              >
              </el-date-picker>
            </div>
          </template>
          <span class="pay_status_titiles">支付状态</span>
          <el-select
            v-model="queryParam.payStatus"
            placeholder="请选择支付状态"
            class="pay_status_select"
            size="medium"
            :clearable="true"
          >
            <el-option
              v-for="item in payStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="buttonList">
            <template
              ><el-button
                size="small"
                type="success"
                round
                class="search_btn"
                @click="search"
                >搜索</el-button
              ></template
            >
            <template>
              <!-- 添加导出按钮,-->
              <el-button
                size="small"
                type="success"
                round
                class="search_btn"
                @click="exportButton"
                >导出</el-button
              ></template
            >
          </div>
        </div>
      </div>
    </div>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      highlight-current-row
      class="juice_table common-table"
      show-overflow-tooltip
      height="100%"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column
        prop="orderId"
        label="订单编号"
        stripe="true"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="equipmentName"
        label="设备名称"
        stripe="true"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="addressStr"
        label="省市区"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="equipmentAddress"
        label="放置区域"
        stripe="true"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="userName"
        label="商家名称"
        stripe="true"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column prop="price" label="金额">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.totalAmount / 100
          }}</span>
          元
        </template>
      </el-table-column>
      <el-table-column prop="addTime" label="购买时间" show-overflow-tooltip="">
      </el-table-column>
      <el-table-column prop="payType" label="支付方式" show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="margin-left: 10px">
            <img
              v-if="scope.row.payType === 1"
              src="../assets/wxPay.png"
              alt="微信"
              style="width: 24px; height: 24px"
            />
            <img
              v-else-if="scope.row.payType === 2"
              src="../assets/pay-alipay.png"
              alt="支付宝"
              style="width: 24px; height: 24px"
            />
            <img
              v-else-if="scope.row.payType === 3"
              src="../assets/yinlian.png"
              alt="银联"
              style="width: 24px; height: 24px"
            />
            <img
              v-else-if="scope.row.payType === 4"
              src="../assets/scanPay.png"
              alt="被扫"
              style="width: 24px; height: 24px"
            />
            <img
              v-else-if="scope.row.payType === 0"
              src="../assets/free.png"
              alt="被扫"
              style="width: 24px; height: 24px"
            />
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="payStatus" label="订单状态">
        <!-- 1 未支付 2已支付 3全部退款 4已取消  5 已分账 6 全部退款0 不支付或者线下支付-->
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.payStatus == 1
              ? "未支付"
              : scope.row.payStatus == 2
              ? "已支付"
              : scope.row.payStatus == 3
              ? "全部退款"
              : scope.row.payStatus == 4
              ? "已取消"
              : scope.row.payStatus == 5
              ? "已分账"
              : scope.row.payStatus == 6
              ? "全部退款"
              : "不支付或者线下支付"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scope">
          <div class="order-button-group">
            <el-button
              type="primary"
              size="mini"
              @click="getPayDetail(scope.row)"
              v-if="scope.row.payStatus == 5"
              >分账详情</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="deleteRow(scope.row)"
              v-if="
                scope.row.payStatus == 1 ||
                scope.row.payStatus == 3 ||
                scope.row.payStatus == 4
              "
              >删除</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="getOrderDetail(scope.row)"
              >订单信息</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="分账详细信息"
      :visible.sync="dialogVisible"
      class="accountDetail"
    >
      <el-alert
        v-if="subAccounstDetail.length != 0"
        title="分账金额将于分账时间的次日到账,请注意查收;如果未能到账,请及时联系客服处理"
        type="error"
      >
      </el-alert>
      <el-table :data="subAccounstDetail">
        <el-table-column prop="ownerName" label="分账接收方"></el-table-column>
        <el-table-column prop="price" label="分账金额">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.price / 100 }}</span>
            元
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="分账时间"></el-table-column>
      </el-table>
    </el-dialog>
    <user-list-dialog
      :dialogVisible="UserListDialogVisible"
      :user="user"
      @chooseUser="handleUserSelect"
    />
    <equipment-dialog
      :userId="queryParam.searchUserId"
      @select="handleEquipmentSelect"
    />
    <el-dialog
      title="选择导出类型"
      :visible.sync="exportFileTypeDialog"
      width="30%"
      center
    >
      <el-radio-group v-model="exportType" class="exportTypeRadio">
        <el-row class="radio-row"
          ><el-radio label="1">导出销售记录信息Excel表单</el-radio></el-row
        >

        <el-row class="radio-row" v-if="userInfo.role == 1">
          <el-radio label="2"
            >导出商家收益信息Excel表单(只根据时间周期和时间范围筛选)</el-radio
          >
        </el-row>
      </el-radio-group>
      <div class="export_btn">
        <el-button
          size="small"
          type="success"
          round
          @click="exportSaleRecordButton"
          >导出</el-button
        >
      </div>
    </el-dialog>
    <el-pagination
      class="pager"
      background
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="pageData.pageSize"
      :page-sizes="[10, 20, 50]"
      :total="total"
      @current-change="handlePage"
      :current-page.sync="currentPage"
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
    >
    </el-pagination>
    <!-- 订单详情组件 点击弹出  -->
    <el-dialog
      title="订单详情"
      :visible.sync="orderDetailDialogVisible"
      width="50%"
      center
    >
      <el-card>
        <el-table
          :data="orderDetailList"
          stripe
          style="width: 100%"
          highlight-current-row
          show-overflow-tooltip
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column
            prop="orderNum"
            label="订单编号"
            stripe="true"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="productTypeName"
            label="产品名称"
            stripe="true"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="productTasteName"
            label="产品口味"
            stripe="true"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="price"
            label="产品价格"
            stripe="true"
            show-overflow-tooltip
            :formatter="formatPrice"
          >
          </el-table-column>
          <!-- 1 未支付 2已支付 3已退款 4已取消  0 不支付或者线下支付 -->
          <el-table-column
            prop="payStatus"
            label="订单状态"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{
                scope.row.payStatus == 1
                  ? "未支付"
                  : scope.row.payStatus == 2
                  ? "已支付"
                  : scope.row.payStatus == 3
                  ? "已退款"
                  : scope.row.payStatus == 4
                  ? "已取消"
                  : "不支付或者线下支付"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="addTime"
            label="创建时间"
            stripe="true"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" min-width="85">
            <template slot-scope="scope">
              <div class="button-group">
                <el-button
                  type="danger"
                  size="mini"
                  @click="orderProductRefundButton(scope.row)"
                  v-if="userInfo.role == 1 && scope.row.payStatus == 2"
                  >退款</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import qs from "qs";
import {
  deleteOrder,
  getEquipmentList,
  getFlavorsList,
  getOrderList,
  getProductCateList,
  getSubAccountMsg,
  getUser,
  orderProductRefund,
} from "../api";
import UserListDialog from "@/components/UserListDialog.vue";
import EquipmentDialog from "@/components/EquipmentDialog.vue";

export default {
  components: {
    UserListDialog,
    EquipmentDialog,
  },
  computed: {
    UserListDialogVisible: {
      get() {
        return this.$store.state.UserListDialogVisible;
      },
      set(value) {
        this.$store.commit("updateUserListDialogVisibility", value);
      },
    },
    EquipmentDialogVisible: {
      get() {
        return this.$store.state.EquipmentDialogVisible;
      },
      set(value) {
        this.$store.commit("updateEquipmentDialogVisibility", value);
      },
    },
  },
  data() {
    return {
      orderDetailDialogVisible: false, //订单详情弹窗
      dialogVisible: false, //支付详情弹窗
      choseDataTypedialogVisible: false, //导出时间范围弹窗
      exportFileTypeDialog: false, //导出类型弹窗
      exportType: null, //导出类型
      selectedOwner: null, //选择的商家
      choseUserName: "", //选择的商家名字
      currentPage1: 1,
      currentPage: 1,
      totalPages1: 1,
      exportParams: {
        startTime: null, //开始时间
        endTime: null, //结束时间
        timeType: null, //时间周期
        ownerId: null, //选择商家
      },
      ownerList: [],
      queryParam: {
        productType: null,
        juiceType: null,
        iceCreamType: null,
        equipmentId: null,
        timeType: null,
        size: null,
        searchUserId: null,
        ownerId: null,
        startTime: null,
        endTime: null,
        productTaste: null,
        isShowSelf: 0,
        payStatus: null, // 支付状态
      },
      time: "",
      userInfo: {
        name: "",
        role: "",
        id: "",
      },
      typeList: [],
      productTasteList: [],
      equipmentList: [],
      userList: [],
      timeRangeList: [
        { value: 1, label: "本日" },
        { value: 2, label: "本周" },
        { value: 3, label: "本月" },
        { value: 4, label: "本年" },
      ],
      sizeList: [
        { value: 1, label: "小杯" },
        { value: 2, label: "中杯" },
        { value: 3, label: "大杯" },
      ],
      userForm: {
        name: "",
      },
      tableData: [],
      orderDetailList: [],
      subAccounstDetail: [],
      pageData: {
        pageNum: 1,
        pageSize: 10,
      },
      exportUserPagedata: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      equipmentName: "",
      payStatusList: [
        { label: "全部", value: null },
        { label: "待支付", value: 1 },
        { label: "已支付", value: 2 },
        { label: "已退款", value: 3 },
        { label: "已取消", value: 4 },
        { label: "已分账", value: 5 },
        { label: "全部退款", value: 6 },
        { label: "不支付或者线下支付", value: 0 },
      ],
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo, "userInfo");
    this.queryParam.ownerId = this.userInfo.id;
  },
  methods: {
    //订单详情价格除以100
    formatPrice(row, column, cellValue) {
      return (cellValue / 100).toFixed(2) + "元"; // 保留两位小数
    },
    //获取订单详细信息
    getOrderDetail(row) {
      this.orderDetailDialogVisible = true;
      this.orderDetailList = row.orderProductLists;
    },
    //点击退款
    orderProductRefundButton(row) {
      this.$confirm("此操作将退款, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        console.log("orderProductRefund", row);
        this.orderRefund(row);
        this.$message({
          type: "success",
          message: "退款成功!",
        });
      });
    },
    //退款操作
    orderRefund(row) {
      orderProductRefund({
        params: { recordId: row.recordId, orderId: row.orderNum },
      }).then((res) => {
        if (res.data.code === 200) {
          console.log("orderProductRefund", res);
          this.getList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    //选择用户
    handleUserSelect(row) {
      if (row) {
        this.queryParam.searchUserId = row.id;
        this.choseUserName = row.name;
        this.selectedOwner = row;
        // 清除设备选择
        this.removeEquipment();
      }
      this.$store.commit("updateUserListDialogVisibility", false);
    },
    // 打开设备选择弹窗
    openEquipmentDialog(e) {
      e && e.stopPropagation();
      this.$store.commit("updateEquipmentDialogVisibility", true);
    },
    // 处理设备选择
    handleEquipmentSelect(row) {
      if (row) {
        this.queryParam.equipmentId = row.identification;
        this.equipmentName = row.name;
      }
    },
    // 移除设备选择
    removeEquipment() {
      this.queryParam.equipmentId = null;
      this.equipmentName = "";
    },
    exportSaleRecordButton() {
      var url;
      console.log("queryParam", this.queryParam);
      if (this.exportType === "1") {
        // 执行导出销售记录信息的逻辑
        console.log("导出销售记录信息Excel表单");
        url = "/web/order/exportOrderList?" + qs.stringify(this.queryParam);
      } else if (this.exportType === "2") {
        this.queryParam.ownerId = this.userInfo.id;
        // 执行导出商家收益信息的逻辑
        console.log("导出商家收益信息Excel表单");
        url =
          "/web/juiceRecords/exportBusinessIncome?" +
          qs.stringify(this.queryParam);
      } else {
        console.error("未知的导出类型");
      }
      var ele = document.createElement("a");
      ele.download = process.env.VUE_APP_URL + url;
      ele.style.display = "none";
      ele.href = process.env.VUE_APP_URL + url;
      ele.target = "_blank";
      // 触发点击
      document.body.appendChild(ele);
      ele.click();
      // 然后移除
      document.body.removeChild(ele);
      this.exportFileTypeDialog = false;
    },
    removeChoseUser() {
      this.selectedOwner = null;
      this.queryParam.searchUserId = null;
      this.choseUserName = "";
    },
    openDialog(e) {
      e && e.stopPropagation();
      this.$store.commit("updateUserListDialogVisibility", true);
    },
    exportButton() {
      this.exportFileTypeDialog = true;
    },
    handleUserPageChange(val) {
      this.exportUserPagedata.pageNum = val;
      this.getUserPageList();
    },
    searchUserPageList() {
      this.exportUserPagedata.pageNum = 1;
      this.currentPage1 = 1;
      this.getUserPageList();
    },
    getUserPageList() {
      console.log("exportUserPagedata", this.exportUserPagedata);
      getUser({
        params: { role: 2, ...this.exportUserPagedata, ...this.userForm },
      }).then((res) => {
        if (res.data.code === 200) {
          console.log("exportUserPagedata", res);
          this.ownerList = res.data.data;
          this.totalPages1 = res.data.total;
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
    getList() {
      console.log("queryParam", this.queryParam);
      console.log("pageData", this.pageData);
      console.log("time", this.time);
      getOrderList({
        params: { ...this.queryParam, ...this.pageData },
      }).then(({ data }) => {
        if (data.code === 200) {
          console.log("data", data);
          this.tableData = data.data;
          this.total = data.total ? data.total : 0;
        } else {
          this.$message.error(data.message);
        }
      });
    },
    getSubAccountDetail(row) {
      const searchParams = qs.stringify({
        orderId: row.orderId,
        currentUserId: this.userInfo.id,
      });
      getSubAccountMsg("web/sub_account/getSubAccountList", searchParams).then(
        ({ data }) => {
          if (data.code === 200) {
            console.log("data", data);
            this.subAccounstDetail = data.data;
          } else {
            this.$message.error(data.message);
          }
        }
      );
    },
    //选择页码回调函数
    handlePage(pageNum) {
      console.log(pageNum, "pageNum");
      this.pageData.pageNum = pageNum;
      this.getList();
    },
    // 处理每页显示数量变化
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getList();
    },
    search() {
      this.pageData.pageNum = 1;
      this.currentPage = 1; // 将分页组件的当前页码设置为1
      this.getList();
    },
    getPayDetail(row) {
      console.log(row, "row");
      this.dialogVisible = true;
      this.getSubAccountDetail(row);
    },
    equipmentIdClear() {
      this.queryParam.equipmentId = null;
    },
    ownerClear() {
      this.queryParam.ownerId = null;
    },
    deleteRow(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        console.log("delete_row_id", row.id);
        deleteOrder({
          params: { id: row.id },
        }).then(({ data }) => {
          if (data.code === 200) {
            console.log("data", data);
            this.getList();
          } else {
            this.$message.error(data.message);
          }
        });
      });
    },
    //获取产品类型
    getProductType(type) {
      //根据 typeList 动态获取
      let typeList = this.typeList;
      let typeObj = typeList.find((item) => {
        return item.value == type;
      });
      return typeObj ? typeObj.label : "";
    },
  },
  //搜索产品类型切换清空果汁类型和冰激凌类型
  watch: {
    "queryParam.productType": {
      handler: function (newVal) {
        console.log(newVal, "val");
        this.queryParam.juiceType = null;
        this.queryParam.iceCreamType = null;
        //根据产品类型获取产品口味
        getFlavorsList().then((res) => {
          if (res.data.code === 200) {
            this.productTasteList = res.data.data.map((item) => {
              return {
                value: item.id,
                label: item.flavorName,
              };
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      },
    },
    time: {
      //时间不为null,则将时间赋值给开始时间和结束时间 并且时间范围清空
      handler: function (newVal) {
        if (newVal) {
          this.queryParam.startTime = newVal[0];
          this.queryParam.endTime = newVal[1];
          this.queryParam.timeType = null;
        }
        //如果time为null,则将开始时间和结束时间清空
        else {
          this.queryParam.startTime = null;
          this.queryParam.endTime = null;
        }
      },
    },
    "queryParam.timeType": {
      //时间范围不为null,则将时间范围赋值给开始时间和结束时间 并且时间清空
      handler: function (newVal) {
        if (newVal) {
          this.time = null;
          this.queryParam.startTime = null;
          this.queryParam.endTime = null;
        }
      },
    },
    selectedOwner: {
      handler: function (newVal) {
        if (newVal) {
          this.queryParam.searchUserId = newVal.id;
        } else {
          this.queryParam.searchUserId = null;
        }
      },
    },
  },
  mounted() {
    this.getList();
    //获取设备列表
    if (this.userInfo.role == 1) {
      //获取用户列表
      getUser({ params: { role: 2 } }).then((res) => {
        if (res.data.code === 200) {
          //去除掉用户id为1的
          this.userList = res.data.data
            .filter((item) => {
              return item.id != 1;
            })
            .map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            });
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    } else {
      //获取设备列表
      getEquipmentList({ params: { owner: this.userInfo.id } }).then((res) => {
        if (res.data.code === 200) {
          this.equipmentList = res.data.data.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          });
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    }
    //获取产品类型
    getProductCateList().then((res) => {
      if (res.data.code === 200) {
        this.typeList = res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      } else {
        this.$message({
          message: "获取产品分类信息失败",
          type: "error",
        });
      }
      console.log("typeList", this.typeList);
    });
  },
};
</script>
<style lang="less" scoped>
.manage {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;

  .searchParam {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 15px;

    .equipment_titiles,
    .showSelf_titiles,
    .userList_titiles {
      font-size: 14px;
      margin-right: 10px;
    }

    .equipment_select,
    .showSelf_select,
    .choseUserInput {
      width: 180px;
      margin-right: 20px;
    }
  }

  .searchParam2 {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 15px;

    .timeRange_titiles,
    .time_titiles,
    .pay_status_titiles {
      font-size: 14px;
      margin-right: 10px;
    }

    .timeRange_select {
      width: 180px;
      margin-right: 20px;
    }

    .time_select {
      width: 350px;
      margin-right: 20px;
    }

    .pay_status_select {
      width: 180px;
      margin-right: 20px;
    }

    .buttonList {
      margin-left: auto;
      .search_btn {
        margin-left: 10px;
      }
    }
  }

  .juice_table {
    margin-top: 0;
    padding: 0;
    flex: 1;
    overflow: auto;
  }
  .common-table {
    position: relative;
    margin-top: 20px;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .exportChoseUserPaper {
    margin-top: 10px;
    position: absolute;
    right: 20px;
  }
  .pager {
    margin-top: 15px;
    padding-top: 10px;
    text-align: right;
    flex-shrink: 0;
  }
  .export-dialog {
    .exportTypeRadio {
      margin-top: 20px;
      margin-left: 20px;
    }
    .exportTypeStartDatePicker {
      margin-top: 20px;
      margin-left: 20px;
    }
    .exportTypeEndDatePicker {
      margin-top: 20px;
      margin-left: 20px;
    }
    .exportTypeTimeTypeSelectOr {
      margin-top: 20px;
      margin-left: 20px;
    }
    .exportTypeOwnerSelectOr {
      margin-top: 20px;
      margin-left: 20px;
    }
  }
  .exportFileTypeDialogClass {
    width: auto;
    .radio-row {
      margin-bottom: 20px; /* 增加行间距 */
    }
    .exportTypeRadio {
      margin-top: 20px;
      margin-left: 20px;
    }
  }
  .buttonList {
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    .search_btn {
      margin-left: 20px;
    }
  }
  .choseOwnerButton {
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    .el-button {
      margin-left: 20px;
      width: 100px;
    }
    .el-input {
      width: 200px;
    }
  }
}
</style>
