<template>
  <div class="equipment-type">
    <!-- 搜索和操作区域 -->
    <div class="operation-area">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="类型名称">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入类型名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="searchForm.status"
            placeholder="请选择状态"
            clearable
          >
            <el-option label="启用" :value="1" />
            <el-option label="禁用" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="operation-buttons">
        <el-button type="primary" @click="handleAdd">新增类型</el-button>
      </div>
    </div>

    <!-- 表格区域 -->
    <el-table :data="tableData" v-loading="loading" border style="width: 100%">
      <el-table-column type="index" label="序号" width="50" align="center" />
      <el-table-column prop="name" label="类型名称" min-width="120" />
      <el-table-column
        prop="description"
        label="描述"
        min-width="150"
        show-overflow-tooltip
      />
      <el-table-column label="图片" width="120" align="center">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.image"
            style="width: 50px; height: 50px"
            :src="scope.row.image"
            :preview-src-list="[scope.row.image]"
          />
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        min-width="120"
        show-overflow-tooltip
      />
      <el-table-column prop="status" label="状态" width="80" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status === 1 ? 'success' : 'info'">
            {{ scope.row.status === 1 ? "启用" : "禁用" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="addTime"
        label="创建时间"
        width="160"
        align="center"
      />
      <el-table-column
        prop="updateTime"
        label="更新时间"
        width="160"
        align="center"
      />
      <el-table-column label="操作" width="150" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button
            type="text"
            size="small"
            class="delete-button"
            @click="handleDelete(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 新增/编辑对话框 -->
    <el-dialog
      :title="dialogType === 'add' ? '新增设备类型' : '编辑设备类型'"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      @closed="handleDialogClosed"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        class="equipment-type-form"
      >
        <el-form-item label="类型名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入类型名称" />
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input
            v-model="form.description"
            type="textarea"
            :rows="3"
            placeholder="请输入描述"
          />
        </el-form-item>
        <el-form-item label="图片" prop="image">
          <image-uploader v-model="form.image" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            :rows="2"
            placeholder="请输入备注"
          />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEquipmentTypeList,
  addEquipmentType,
  updateEquipmentType,
  deleteEquipmentType,
} from "@/api";
import ImageUploader from "@/components/ImageUploader.vue";

export default {
  name: "EquipmentType",
  components: {
    ImageUploader,
  },
  data() {
    return {
      // 搜索表单
      searchForm: {
        name: "",
        status: "",
      },
      // 表格数据
      tableData: [],
      loading: false,
      // 分页信息
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 对话框
      dialogVisible: false,
      dialogType: "add", // add or edit
      submitting: false,
      // 表单数据
      form: {
        id: null,
        name: "",
        description: "",
        image: "",
        remark: "",
        status: 1,
      },
      // 表单验证规则
      rules: {
        name: [
          { required: true, message: "请输入类型名称", trigger: "blur" },
          { max: 50, message: "长度不能超过50个字符", trigger: "blur" },
        ],
        description: [
          { max: 200, message: "长度不能超过200个字符", trigger: "blur" },
        ],
        remark: [
          { max: 200, message: "长度不能超过200个字符", trigger: "blur" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表数据
    async getList() {
      this.loading = true;
      try {
        const params = {
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
          ...this.searchForm,
        };
        const res = await getEquipmentTypeList({ params });
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.page.total = res.data.total;
        } else {
          this.$message.error(res.data.message || "获取列表失败");
        }
      } catch (error) {
        console.error("获取列表失败:", error);
        this.$message.error("获取列表失败");
      } finally {
        this.loading = false;
      }
    },

    // 搜索
    handleSearch() {
      this.page.pageNum = 1;
      this.getList();
    },

    // 重置搜索
    resetSearch() {
      this.searchForm = {
        name: "",
        status: "",
      };
      this.page.pageNum = 1;
      this.getList();
    },

    // 处理分页大小变化
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    },

    // 处理页码变化
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },

    // 新增
    handleAdd() {
      this.dialogType = "add";
      this.dialogVisible = true;
      this.form = {
        id: null,
        name: "",
        description: "",
        image: "",
        remark: "",
        status: 1,
      };
    },

    // 编辑
    handleEdit(row) {
      this.dialogType = "edit";
      this.dialogVisible = true;
      this.form = { ...row };
    },

    // 删除
    handleDelete(row) {
      this.$confirm("确定要删除该设备类型吗？", "提示", {
        type: "warning",
      })
        .then(async () => {
          try {
            const res = await deleteEquipmentType(row.id);
            if (res.data.code === 200) {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error(res.data.message || "删除失败");
            }
          } catch (error) {
            console.error("删除失败:", error);
            this.$message.error("删除失败");
          }
        })
        .catch(() => {});
    },

    // 提交表单
    handleSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.submitting = true;
          try {
            const api =
              this.dialogType === "add"
                ? addEquipmentType
                : updateEquipmentType;
            const res = await api(this.form);
            if (res.data.code === 200) {
              this.$message.success(
                this.dialogType === "add" ? "添加成功" : "更新成功"
              );
              this.dialogVisible = false;
              this.getList();
            } else {
              this.$message.error(res.data.message || "操作失败");
            }
          } catch (error) {
            console.error("操作失败:", error);
            this.$message.error("操作失败");
          } finally {
            this.submitting = false;
          }
        }
      });
    },

    // 对话框关闭后的处理
    handleDialogClosed() {
      this.$refs.form && this.$refs.form.resetFields();
      this.form = {
        id: null,
        name: "",
        description: "",
        image: "",
        remark: "",
        status: 1,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.equipment-type {
  padding: 20px;

  .operation-area {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .search-form {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .operation-buttons {
      margin-left: 16px;
    }
  }

  .pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .delete-button {
    color: #f56c6c;
  }

  .equipment-type-form {
    max-width: 400px;
    margin: 0 auto;
  }
}

/* 响应式布局 */
@media screen and (max-width: 768px) {
  .equipment-type {
    .operation-area {
      flex-direction: column;

      .operation-buttons {
        margin-left: 0;
        margin-top: 16px;
      }
    }

    .search-form {
      .el-form-item {
        margin-right: 0;
        width: 100%;
      }
    }
  }
}
</style>
